<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-hover table-striped table-bordered">
            <thead>
              <th class="text-right">#</th>
              <th>{{ $t('team') }}</th>
              <th>{{ $tc('point', 3) | toCapitalize }}</th>
            </thead>
            <tbody>
              <tr v-for="(team, ind) in teams" :key="team.id">
                <td class="text-right">{{ positionInRanking + ind }}</td>
                <td>
                  <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: team.slug } }">
                    <img :src="team.image" :alt="team.name" class="img-icon mr-2">
                    {{ team.name }}
                  </router-link>
                </td>
                <td class="text-right">{{ team.points }} {{ $tc('point', team.points) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-pagination align="center" v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="paginate"></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      teams: [],
      currentPage: 1,
      rows: 0,
      perPage: 0,
      path: '',
      positionInRanking: 0
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'ranking')
  },
  created() {
    this.fetchData()
  },
  methods: {
    updatePositionInRanking(currentPage, perPage) {
      this.positionInRanking = (perPage * (currentPage - 1)) +  1
    },
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.tournamentSlug
      this.path = `${lang}/console/${console}/tournament/${slug}/ranking-teams`
      this.$axios.get(this.path).then((response) => {
        const data = response.data.data
        this.teams = data.data
        this.rows = data.total
        this.perPage = data.per_page
        this.currentPage = data.current_page
        this.updatePositionInRanking(data.current_page, data.per_page)
      })
    },
    paginate(page) {
      this.$axios.get(this.path, { params: { page: page } }).then((response) => {
        const data = response.data.data
        this.teams = data.data
        this.rows = data.total
        this.perPage = data.per_page
        this.currentPage = data.current_page
        this.updatePositionInRanking(data.current_page, data.per_page)
      })
    }
  }
}
</script>
